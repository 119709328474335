<template>
  <section class="favor-bar">
    <div class="favor-title">
      <img src="@/assets/images/icons/icon-shoucangjia.png" style="width: 18px;vertical-align: sub;">
      <span class="title">收藏夹</span>
      <i class="icon el-icon-plus" @click="dialogFormVisible = true"></i>
    </div>
    <section class="side-bar">
      <div class="fovorfolder" v-for="(i,index) in list" :class="activeId == index?'actived':''" @click="goTo(i.id,index)">
        <i class="el-icon-star-on"></i>
        <el-tooltip class="item" effect="dark" :content="i.folderName" placement="bottom" :disabled="i.folderName.length>10?false:true">
          <span class="folder-name">{{i.folderName}}</span>
        </el-tooltip>
        <i class="el-icon-delete" @click.stop="deleteItem(i.id)"></i>
      </div>
      <!-- <el-menu
        :default-active="id"
        :default-openeds="['0']"
        class="el-menu-vertical-demo">
        <el-submenu index="0">
          <template slot="title">
            <span>收藏夹</span>
          </template>
          <el-menu-item-group>
            <el-tooltip v-for="i in list" :content="i.folderName"  v-if="i.folderName.length > 8" placement="bottom" effect="light">
              <el-menu-item :key="i.id" :index="i.id" @click="goTo(i.id)">
                <i class="el-icon-remove" @click.stop="deleteItem(i.id)"></i>
                <span>{{ i.folderName }}</span>
              </el-menu-item>
            </el-tooltip>
            <el-menu-item :key="i.id" :index="i.id" v-else @click="goTo(i.id)">
              <i class="el-icon-remove" @click.stop="deleteItem(i.id)"></i>
              <span>{{ i.folderName }}</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-menu> -->
    </section>
    <!-- <div class="block"></div> -->
    <!-- <el-button class="addFavorites" icon="el-icon-plus" @click="dialogFormVisible = true">创建收藏夹</el-button> -->
    <el-dialog title="新建收藏夹" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="收藏夹名称" label-width="120px">
          <el-input v-model="form.folderName" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addItems">确 定</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import { getFavorList, addFavor, deleteFavor } from '@/api/favor'
import event from '@/utils/eventHub.js'
export default {
  mounted () {
    this.getFavorList()
    window.addEventListener('scroll', this.handleScroll)
  },
  data () {
    return {
      top: 130,
      id:'',
      dialogFormVisible: false,
      editVisible: false,
      name: null,
      list: [],
      activeId:0,
      form:{
        folderName:''
      }
    }
  },
  methods: {
    // 获取收藏夹列表
    async getFavorList () {
      const res = await getFavorList()
      if(res.data.length > 0){
        this.id = res.data[0].id
        this.list = res.data
        event.$emit('favorList', this.list)
        event.$emit('favorId', this.id)
        sessionStorage.setItem('favorList',JSON.stringify(res.data))
      }
    },
    goTo (id,index) {
      event.$emit('favorId', id);
      this.activeId = index
    },
    deleteItem (id) {
      this.$confirm('此操作将同时删除该收藏夹下的数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await deleteFavor({id:id})
        if(res.code == 0){
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.getFavorList()
        }else{
          this.$message({
            type: 'error',
            message: '删除失败!'
          });
        }
      }).catch(() => {

      });

    },
    async addItems () {
      const res = await addFavor({folderName:this.form.folderName})
      if(res.code==0){
        this.$message({
          type: 'success',
          message: res.msg
        });
      }else{
        this.$message({
          type: 'error',
          message: res.msg
        });
      }
      this.dialogFormVisible = false
      this.getFavorList()
      this.form.folderName = ''
    },
    handleScroll () { // 改变元素#searchBar的top值
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // var offsetTop = document.querySelector('#searchBar').offsetTop
      if (scrollTop >= 100) {
        this.top = 30
      } else if (scrollTop < 100) {
        this.top = 130 - scrollTop
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/index';
/* .favor-bar{
	.block {
	  width: 200px;
	  height: $contentHeight;
	}
} */
.favor-title{
  background-color: #3472c7;
  height: 40px;
  line-height: 40px;
  color: #fff;
  padding-left: 10px;
  .title{
    margin-left: 5px;
  }
  .icon{
    float: right;
    margin: 12px;
  }
}
.side-bar {
  width: 260px;
  height: calc(100% - 70px);
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  // position: absolute;
  .fovorfolder{
    font-size: 16px;
    color: #696969;
    height: 40px;
    line-height: 40px;
    padding: 0 15px;
    .el-icon-star-on{
      color: #f1b410;
      margin-right: 5px;
      float: left;
      margin-top: 11px;
    }
    .el-icon-delete{
      float: right;
      color: #d9d9d9;
      cursor: pointer;
      margin-top: 10px;
    }
    .folder-name{
      display: inline-block;
      width: 180px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
  }
  .actived{
    color:#3472c7;
    background-color: #f0f7ff;
  }
  .fovorfolder:hover{
    background-color: #fefefe;
  }
  .el-menu-item {
    width: 200px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
  .el-icon-remove{
    color: #f56c6c !important;
  }
}
.el-menu{
  height: calc(100vh - 210px);
}
.addFavorites {
  position: absolute;
  left: 50px;
  bottom: 0;
}
.el-submenu .el-menu {
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
   width: 8px;
  }
  &::-webkit-scrollbar-track {
   background-color:#f8f8f8;
   -webkit-border-radius: 2em;
   -moz-border-radius: 2em;
   border-radius:2em;
  }
  &::-webkit-scrollbar-thumb {
   background-color:#dddddd;
   -webkit-border-radius: 2em;
   -moz-border-radius: 2em;
   border-radius:2em;
  }
}
</style>
