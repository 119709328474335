<template>
  <section class="perception-report-wrap">
    <favoriteBar class="favorite" ref="favorbar" v-if="$route.path === '/perceptionReport'"/>
    <section class="perception-content">
      <router-view />
    </section>
  </section>
</template>

<script>
import FavoriteBar from '@/components/favoriteBar'
export default {
  components: {
    FavoriteBar
  }
}
</script>

<style lang="scss">
@import '@/styles/index';
.perception-report-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  .perception-content{
    width: 100%;
    min-width: calc(100% - 280px);
    // margin-left: 20px;
  }
}
</style>
